
import { defineComponent } from "vue";

export default defineComponent({
  name: "DefaultPopup",
  props: {
    onClose: {
      type: Function,
      default() {
        return {};
      },
    },
    message: {
      type: String,
    },
  },
  components: {},
  methods: {
    handleCloseModal() {
      this.onClose();
    },
  },
});

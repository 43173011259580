
import { defineComponent } from "vue";
import Lock from "@/assets/svg/Lock.svg?inline";
import Heart from "@/assets/svg/heart.svg?inline";

export default defineComponent({
  name: "CardHome",
  data() {
    return {
      idFavorite: "",
    };
  },
  props: {
    imgCatalog: {
      type: String,
    },
    attributes: {
      type: Array,
    },
    name: {
      type: String,
    },
    favorite: {
      type: Function,
    },
    id: {
      type: String,
    },
  },
  components: {
    Lock,
    Heart,
  },
  mounted() {
    if (localStorage.getItem("idFavorite")) {
      this.idFavorite = localStorage.getItem("idFavorite").toString().replace(/["']/g,'');
    }
  },
  methods: {},
});


import { defineComponent } from "vue";

export default defineComponent({
  name: "NavMenu",
  data() {
    return {
      data: {
        mint: "Mint",
        stake: "Stake",
        opensea: "Opensea",
        viewDemo: "View demo",
      },
    };
  },
  components: {},
  mounted() {},
  methods: {},
});


import { defineComponent } from "vue";

import Main from "@/templates/Main.vue";
import RunningLine from "@/components/molecules/MainPage/RunningLineBlock.vue";
import MainButton from "@/components/atoms/buttons/MainButton.vue";
import InfoBlock from "@/components/molecules/MainPage/InfoBlock.vue";
import TopSlider from "@/components/molecules/MainPage/TopSlider.vue";
import Collect from "@/components/molecules/MainPage/Collect.vue";
import PlanTrain from "@/components/molecules/MainPage/PlanTrain.vue";
import Timeline from "@/components/molecules/MainPage/TimeBlock.vue";
import MoreAboutNFT from "@/components/molecules/MainPage/MoreAboutNFT.vue";

export default defineComponent({
  name: "HomeSection",
  data() {
    return {
      data: {
        joinUs: "Join us",
        slogan: "Be the meta champion",
        topText:
          "Collect, play and earn in a first metaverse-ready hockey game",
      },
      slides: [
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
        require("../../assets/image/test1.png"),
        require("../../assets/image/test2.png"),
        require("../../assets/image/test3.png"),
      ],
      slidesCollect: [
        {
          image: require("../../assets/image/test-home-slider/test1.png"),
          name: 'Unnamed Player',
          id: '100'
        },
        {
          image: require("../../assets/image/test-home-slider/test2.png"),
          name: 'Unnamed Player',
          id: '200'
        },
        {
          image: require("../../assets/image/test-home-slider/test3.png"),
          name: 'Unnamed Player',
          id: '300'
        },
        {
          image: require("../../assets/image/test-home-slider/test4.png"),
          name: 'Unnamed Player',
          id: '400'
        },
        {
          image: require("../../assets/image/test-home-slider/test1.png"),
          name: 'Unnamed Player',
          id: '500'
        },
        {
          image: require("../../assets/image/test-home-slider/test2.png"),
          name: 'Unnamed Player',
          id: '600'
        },
        {
          image: require("../../assets/image/test-home-slider/test3.png"),
          name: 'Unnamed Player',
          id: '700'
        },
      ],
      token: null,
      signIn: null,
    };
  },
  mounted() {
    this.token = localStorage.getItem("phantom-token");
    this.signIn = localStorage.getItem("sign-in");
    if (this.token != null) {
      this.data.joinUs = "To dashboard";
    } else if (this.signIn == '"sign-in"' && this.token == undefined) {
      this.data.joinUs = "Sign in";
    }
  },
  components: {
    Main,
    RunningLine,
    InfoBlock,
    TopSlider,
    MainButton,
    Collect,
    PlanTrain,
    MoreAboutNFT,
    Timeline,
  },
  methods: {
    goToDashboard() {
      if (this.token == null) {
        this.$router.push("/registration-phantom");
      } else if (this.token != null) {
        this.$router.push("/dashboard/mint");
        this.data.joinUs = "To dashboard";
      }
    },
    favorite(id) {
      localStorage.setItem('idFavorite', JSON.stringify(id))
    }
  },
});

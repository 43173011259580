
import { defineComponent } from "vue";
import Medium from "@/assets/svg/medium.svg?inline";
import Discord from "@/assets/svg/discord.svg?inline";
import Twitter from "@/assets/svg/twitter.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default defineComponent({
  name: "Footer",
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("Введите"),
      email: yup.string().required("Введите"),
    });

    return {
      isModalOpen: false,
      schema,
    };
  },
  components: {
    Medium,
    Discord,
    Twitter,
    DefaultPopup,
    Form,
    Field,
    ErrorMessage,
  },
  mounted() {},
  computed: {
    getFullYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    handleCloseModal() {
      this.isModalOpen = false;
    },
    sendContactForm() {
      console.log("send");
    },
  },
});

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c7b8ace"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-menu" }
const _hoisted_2 = {
  class: "nav-menu__link",
  target: "_blank",
  href: "https://opensea.io/"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "nav-menu__link",
      to: "/mint"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.mint), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "nav-menu__link",
      to: "/stake"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.stake), 1)
      ]),
      _: 1
    }),
    _createElementVNode("a", _hoisted_2, _toDisplayString(this.data.opensea), 1),
    _createVNode(_component_router_link, {
      class: "nav-menu__link",
      to: "/view-demo"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.viewDemo), 1)
      ]),
      _: 1
    })
  ]))
}

import { defineComponent } from "vue";

import NavMenu from "@/components/molecules/NavMenu.vue";
import NavMenuMob from "@/components/molecules/NavMenuMob.vue";
import Logo from "@/assets/svg/logo.svg?inline";
import MainButton from "@/components/atoms/buttons/MainButton.vue";
import MenuIcon from "@/assets/svg/menu-icon.svg?inline";

export default defineComponent({
  name: "Header",
  data() {
    return {
      data: {
        joinUs: "Join us",
      },
      mobileMenu: false,
      token: null,
      signIn: null,
    };
  },
  components: {
    NavMenu,
    Logo,
    MainButton,
    NavMenuMob,
    MenuIcon,
  },
  mounted() {
    this.token = localStorage.getItem("phantom-token");
    this.signIn = localStorage.getItem("sign-in");
    if (this.token != null) {
      this.data.joinUs = "To dashboard";
    } else if (this.signIn == '"sign-in"' && this.token == null) {
      this.data.joinUs = "Sign in";
    }
  },
  methods: {
    openMobMenu() {
      this.mobileMenu = true;
    },
    closeMobMenu() {
      this.mobileMenu = false;
    },
    goToDashboard() {
      if (this.token == null) {
        this.$router.push("/registration-phantom");
      } else if (this.token != null) {
        this.$router.push("/dashboard/mint");
        this.data.joinUs = "To dashboard";
      }
    },
  },
});


import { defineComponent } from "vue";

import CloseIcon from "@/assets/svg/close-icon.svg?inline";
import MainButton from "@/components/atoms/buttons/MainButton.vue";

export default defineComponent({
  name: "NavMenu",
  data() {
    return {
      data: {
        mint: "Mint",
        stake: "Stake",
        opensea: "Opensea",
        viewDemo: "View demo",
        joinUs: "Join us",
      },
    };
  },
  components: {
    CloseIcon,
    MainButton,
  },
  props: {
    handleClose: {
      type: Function,
      default() {
        return {};
      },
    },
  },
});
